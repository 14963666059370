<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Product Categories/List</h4>
          <div>
            <router-link :to="can('product-category-create') ? {name:'appProductCategoryCreate'} : '#'">
              <span :title="can('product-category-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-12 col-md-4">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" class="" :options="paginationList" :close-on-select="true"
                                label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <label>Status</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedStatus"
                                :options="statusList"
                                :close-on-select="true"
                                placeholder="Select status" label="name" track-by="name" :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <label>Parent</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedParentProductCategory"
                                :options="productCategoryList"
                                :close-on-select="true"
                                placeholder="Select status" label="name" track-by="name" :show-labels="false"/>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9">
                <input v-model="getProductCategoriesParams.name" class="form-control search-product-input-element"
                       type="text"
                       placeholder="Search a product category by name">
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyProductCategoryFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'5%'}">SL</th>
                    <th class="position-relative" :style="{width:'20%'}">NAME</th>
                    <th class="position-relative" :style="{width:'30%'}">Parent</th>
                    <th class="position-relative" :style="{width:'20%'}">SLUG</th>
                    <th class="position-relative" :style="{width:'20%'}">STATUS</th>
                    <th class="position-relative" :style="{width:'5%'}">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(productCategory, index) in productCategories" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ productCategory.name }}</td>
                    <td>{{ productCategory.parent && productCategory.parent.name ? productCategory.parent.name : ''  }}</td>
                    <td>{{ productCategory.slug }}</td>
                    <td><span
                        :class="productCategory.status === 'Active'? 'badge badge-sm badge-light-success': 'badge badge-sm badge-light-danger' ">{{
                        productCategory.status
                      }}</span></td>
                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <div>
                          <div class="cursor-pointer" :style="{height:'18px'}"
                               @click="getSingleProductCategory(productCategory.id)" data-toggle="modal"
                               data-target="#productCategoryDetails">
                            <i class='bx bx-show'></i>
                          </div>
                        </div>
                        <div class="dropdown py-0 px-0 view-edit-delte-icon" :style="{height:'18px'}">
                                                    <span type="button" class="px-0 py-0" data-toggle="dropdown"
                                                          aria-haspopup="true" aria-expanded="false">
                                                        <i class='bx bx-dots-vertical-rounded h-100'></i>
                                                    </span>
                          <div class="dropdown-menu" style="min-height: 100px">

                            <button :disabled="!can('product-category-update')" @click="getSingleProductCategory(productCategory.id)" data-toggle="modal"
                                    :title="can('product-category-update') ? 'Update' : 'Update Forbidden'"
                                    data-target="#updateProductCategoryModal" class="dropdown-item">Update
                            </button>
                            <button :disabled="!can('product-category-delete')" @click="modelIdForDelete = productCategory.id" data-toggle="modal"
                                    :title="can('product-category-delete') ? 'Delete' : 'Delete Forbidden'"
                                    data-target="#deleteProductCategoryModal" class="dropdown-item">Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyProductCategoryFilter"/>
              </div>
            </div>
          </div>
        </div>
        <ProductCategoryModal :product-category="this.productCategory"/>
        <ProductCategoryUpdateModal :product-category="this.productCategory" :product-category-list="productCategoryList"/>
        <ProductCategoryDeleteAlertModal modal-name="deleteProductCategoryModal" model-name="productCategory" :model-id="modelIdForDelete"
                                 @confirmModelDeletion="onConfirmModelDeletion"/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/backEnd/AppLayout";
import ProductCategoryDeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
import ProductCategoryUpdateModal from "@/views/backEnd/productCategories/includes/ProductCategoryUpdateModal";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import ProductCategoryModal from "@/views/backEnd/productCategories/includes/ProductCategoryViewModal";
import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "ProductCategoryList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    ProductCategoryUpdateModal,
    ProductCategoryDeleteAlertModal,
    ListPagination,
    ProductCategoryModal
  },
  data() {
    return {
      modelIdForDelete: '',
      productCategory: {},

      selectedParentProductCategory: {
        value: '',
        name: 'None',
      },

      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedStatus: {
        value: '',
        name: 'Any'
      },
      getProductCategoriesParams: {
        parent_id: '',
        name: '',
        status: '',
        with_relation: ['parent'],
        order_by_name: 'ASC',
        paginate: 1,
        pagination: '',
        page: '',
      },
      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination'],
      },
    }
  },
  watch: {
    selectedPagination: function (pagination) {
      this.getProductCategoriesParams.pagination = pagination.value;
    },
    selectedStatus: function (status) {
      this.getProductCategoriesParams.status = status.value;
    },
    selectedParentProductCategory(selectedParentProductCategory) {
      this.getProductCategoriesParams.parent_id = selectedParentProductCategory.value;
    }
  },
  computed: {
    ...mapGetters({
      paginateLinks: 'appProductCategories/paginateLinks',
      productCategories: 'appProductCategories/productCategories',
    }),
    paginationList() {
      return [
        {value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'},
        {value: 25, name: '25 Entries'},
        {value: 50, name: '50 Entries'},
        {value: 100, name: '100 Entries'}
      ];
    },
    statusList() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ];
    },
    productCategoryList() {
      let productCategories = this.productCategories ? this.productCategories.map(productCategory => {
        return {
          value: productCategory.id,
          name: productCategory.name,
        }
      }) : [];

      return [
        {
          value: '',
          name: 'None',
        },
        ...productCategories
      ]
    },
  },
  methods: {
    ...mapActions({
      getProductCategories: 'appProductCategories/getProductCategories',
      getSettings: 'appSettings/getSettings',
      deleteProductCategoryOnList: 'appProductCategories/deleteProductCategoryOnList',

      resetProductCategories: 'appProductCategories/resetProductCategories',
    }),

    async getProductCategoryList() {
      await this.getProductCategories(this.getProductCategoriesParams).then((response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      })
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async applyProductCategoryFilter(pageNumber) {
      this.getProductCategoriesParams.page = pageNumber;
      await this.loader(true);
      await this.getProductCategoryList();
      await this.loader(false);
    },

    async getSingleProductCategory(id) {
      console.log('id', id);
      let index = this.productCategories.findIndex(item => item.id === id);
      this.productCategory = index !== -1 ? this.productCategories[index] : {}
    },

    async deleteSingleProductCategoryOnList(id) {
      this.deleteProductCategoryOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          this.showToastMessage({type: 'success', message: 'Product Category deleted successfully'});
          return;
        }
        this.showToastMessage(response);
      });
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleProductCategoryOnList(confirmModelDeletion.modelId);
    },
  },
  async mounted() {
    this.loader(true);
    await this.getSettingList();
    await this.getProductCategoryList();
    this.loader(false);
  },

  beforeUnmount() {
    this.resetProductCategories();
  }

}
</script>

<style scoped>

</style>