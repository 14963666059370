<template>
  <div class="modal fade " id="updateProductCategoryModal" tabindex="-1" role="dialog"
       aria-labelledby="updateProductCategoryModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title white" id="">Product Category Update</h5>
          <button @click="resetProductCategory" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <div class="">
                  <label>Category Name</label>
                  <input v-model="putProductCategoryData.name" type="text" class="form-control"
                         placeholder="Enter Product Category Name" name="productName">
                  <div class="text-danger" v-if="errors.name">{{ errors.name }}</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
              <div class="form-group">
                <div class="controls">
                  <label>Parents Category</label>
                  <VueMultiselect :allowEmpty="false" v-model="selectedParentProductCategory" class="multiselect-blue"
                                  :options="productCategoryList"
                                  :close-on-select="true" placeholder="Select Category" label="name" track-by="name"
                                  :show-labels="false"/>
                  <div class="text-danger" v-if="errors.parentId">{{ errors.parentId }}</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
              <div class="form-group">
                <div class="controls">
                  <label>Status</label>
                  <VueMultiselect :allowEmpty="false" v-model="selectedStatus" class="multiselect-blue"
                                  :options="statusList"
                                  :close-on-select="true" placeholder="Select Status" label="name" track-by="name"
                                  :show-labels="false"/>
                  <div class="text-danger" v-if="errors.status">{{ errors.status }}</div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <div class="">
                  <label>Description</label>
                  <textarea v-model="putProductCategoryData.description"
                            class="form-control"
                            aria-placeholder="Enter Product Category description"> </textarea>
                  <div class="text-danger" v-if="errors.description">{{ errors.description }}</div>
                </div>
              </div>
            </div>


          </div>
        </div>

        <div class="modal-footer border-0 py-1">
          <button @click="updateSingleProductCategory" type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Update Product Category</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  name: "ProductCategoryUpdateModal",
  props: ['productCategory', 'productCategoryList'],
  mixins: [ShowToastMessage],
  data() {
    return {
      selectedStatus: {
        value: 0,
        name: 'Inactive'
      },
      selectedParentProductCategory: {
        value: '',
        name: 'None'
      },

      putProductCategoryData: {
        name: '',
        parent_id: '',
        status: '',
        description: '',
      },
      errors: {
        name: '',
        parentId: '',
        status: '',
        description: '',
      }
    }
  },
  computed: {
    statusList() {
      return [
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ]
    },

  },
  watch: {
    productCategory(productCategory) {
      this.putProductCategoryData.name = productCategory.name;
      this.putProductCategoryData.parent_id = productCategory.parent && productCategory.parent.id ? productCategory.parent.id : '';
      this.putProductCategoryData.status = productCategory.status === 'Active' ? 1 : 0;
      this.putProductCategoryData.description = productCategory.description;

      this.selectedStatus.value = productCategory.status === 'Active' ? 1 : 0;
      this.selectedStatus.name = productCategory.status ? productCategory.status : 'Inactive';

      this.selectedParentProductCategory.value = productCategory.parent && productCategory.parent.id ? productCategory.parent.id : '';
      this.selectedParentProductCategory.name = productCategory.parent && productCategory.parent.name ? productCategory.parent.name : 'None';
    },
    selectedStatus(selectedStatus) {
      this.putProductCategoryData.status = selectedStatus.value;
    },
    selectedParentProductCategory(selectedParentProductCategory) {
      this.putProductCategoryData.parent_id = selectedParentProductCategory.value;
    },
  },
  methods: {
    ... mapActions({
      putProductCategoryOnList: 'appProductCategories/putProductCategoryOnList',
    }),
    async updateSingleProductCategory() {
      let dataObj = {
        id: this.productCategory.id,
        data: this.putProductCategoryData,
      };

      await this.putProductCategoryOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          let toastObj = {
            message: 'Product Category Updated Successfully',
            type: 'success',
          }
          this.showToastMessage(toastObj);
          this.resetProductCategory();
          return;
        }

        this.errors.name = response?.errors?.name ? response.errors.name : '';
        this.errors.parentId = response?.errors?.parentId ? response.errors.parentId : '';
        this.errors.status = response?.errors?.status ? response.errors.status : '';
        this.errors.description = response?.errors?.description ? response.errors.description : '';

        if (response.message) {
          this.showToastMessage(response);
        }

      });
    },
    resetProductCategory() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
}
</script>

<style scoped>

</style>