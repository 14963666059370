<template>
  <div class="modal fade " id="productCategoryDetails" tabindex="-1" role="dialog"
       aria-labelledby="productCategoryDetailsModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title white" id="">Product Category Details</h5>
          <button @click="resetProductCategory" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <h3>{{ name }}</h3>
          <h5>Parent Category: <span :class="parent.name ? '' : 'text-danger'">{{ parent.name ? parent.name : 'Not Assigned' }}</span></h5>
          <h5>status: <span class="badge" :class="status === 'Active' ? 'badge-success' : 'badge-danger'">{{ status }}</span></h5>
          <p>Slug: {{ slug }}</p>
          <p>Description: {{ description }}</p>
        </div>

        <div class="modal-footer border-0 py-1">
          <button @click="resetProductCategory" type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProductCategoryModal",
  props: ['productCategory'],
  data() {
    return {
      name: '',
      parent: '',
      slug: '',
      status: '',
      description: '',
    }
  },
  watch: {
    productCategory() {
      this.name = this.productCategory && this.productCategory.name ? this.productCategory.name : '';
      this.parent = this.productCategory && this.productCategory.parent ? this.productCategory.parent : '';
      this.slug = this.productCategory && this.productCategory.slug ? this.productCategory.slug : '';
      this.status = this.productCategory && this.productCategory.status ? this.productCategory.status : '';
      this.description = this.productCategory && this.productCategory.description ? this.productCategory.description : '';
    }

  },
  methods: {
    resetProductCategory() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
}
</script>

<style scoped>

</style>